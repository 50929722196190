<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Folha de Pagamentos</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="12" :sm="16">
              <el-row type="flex" justify="end">
                <el-select
                  remote
                  value-key="uid"
                  :loading="!firms?.length"
                  @visible-change="fetchFirms"
                  v-model="firm"
                  no-data-text="Nenhuma empresa cadastrada"
                  size="medium"
                >
                  <template #prefix>Empresa:</template>
                  <el-option
                    v-for="item in firms"
                    :key="item.uid"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-row type="flex" justify="space-between">
      <h4>Mês de referência: {{ dateFormatter.format(referenceDate) }}</h4>
      <el-button-group>
        <el-button
          type="primary"
          icon="el-icon-d-arrow-left"
          @click="backMonths(12)"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          @click="backMonths(1)"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-arrow-right"
          @click="advanceMonths(1)"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-d-arrow-right"
          @click="advanceMonths(12)"
        ></el-button>
      </el-button-group>
    </el-row>

    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="payrolls"
      style="width: 100%; z-index: 0"
      v-if="payrolls"
    >
      <el-table-column prop="name" label="funcionário"> </el-table-column>
      <el-table-column prop="role.name" label="cargo"> </el-table-column>
      <el-table-column
        label="salário"
        :formatter="(r) => formatCurrency(calculateAmount(r))"
      >
      </el-table-column>
      <el-table-column
        label="comissão"
        :formatter="(r) => formatCurrency(r?.contract?.comission)"
      >
      </el-table-column>
      <el-table-column
        label="descontos"
        :formatter="(r) => formatCurrency(calculateDebts(r.debts))"
      >
      </el-table-column>
      <el-table-column
        label="salário liquido"
        :formatter="(r) => formatCurrency(calculateSalary(r))"
      >
      </el-table-column>
    </el-table>
    <el-row justify="center" v-else>
      <el-col :md="24">
        <h5>Selecione uma empresa para visualizar a folha de pagamentos</h5>
      </el-col>
    </el-row>
    <bill-modal
      :showModal="showBillModal"
      :bill="bill"
      @close-modal="showBillModal = false"
      @should-update="fetchFirms"
    ></bill-modal>

    <filter-bills-modal
      :showModal="showFilterBillsModal"
      @close-modal="showFilterBillsModal = false"
      @update-filters="updateFilters"
    ></filter-bills-modal>
  </el-card>
</template>

<script>
import BillModal from "./modals/BillModal.vue";
import FilterBillsModal from "./modals/FilterBillsModal.vue";
import Moment from "moment";
//import { ElNotification } from "element-plus";
export default {
  name: "BillsPage",
  components: { BillModal, FilterBillsModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    firms: null,
    referenceDate: new Date(),
    firm: null,
    payrolls: null,
    filterOptions: {},
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      month: "long",
      year: "numeric",
    }),
    showBillModal: false,
    showBillPaymentModal: false,
    showFilterBillsModal: false,
  }),
  mounted() {
    this.fetchFirms();
  },
  watch: {
    firm() {
      this.fetchPayroll();
    },
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
    formatOrders(b) {
      if (b?.payments) {
        const order = b.payments.reduce(
          (t, p) => (t += p.status === "paid" ? 1 : 0),
          0
        );
        return `${order || 0}/${b.quota || 1}`;
      }
      return `${b.order || "1"}/${b.total || "1"}`;
    },
    calculateAmount(employee) {
      if (employee) {
        var amount = Number(employee.contract.amount_real);
        if (
          new Date(employee.contract.start_at) >
          firstDayInMonth(this.referenceDate)
        )
          amount -=
            Moment(new Date(employee.contract.start_at)).diff(
              Moment(firstDayInMonth(this.referenceDate)),
              "days"
            ) * salaryPerDay(Number(employee.contract.amount_real));
        if (
          !employee.contract.fired_at &&
          new Date(employee.contract.fired_at) >=
            firstDayInMonth(this.referenceDate) &&
          new Date(employee.contract.fired_at) <=
            lastDayInMonth(this.referenceDate)
        )
          amount -= Moment(new Date(employee.contract.fired_at)).diff(
            Moment(lastDayInMonth(this.referenceDate)),
            "days"
          );

        return amount;
      }
    },
    calculateSalary(employee) {
      return (
        (this.calculateAmount(employee) || 0) +
        (employee?.contract?.comission || 0) -
        this.calculateDebts(employee?.debts)
      );
    },
    backMonths(months) {
      this.referenceDate = new Date(
        this.referenceDate.getFullYear(),
        this.referenceDate.getMonth() - months,
        this.referenceDate.getDate()
      );

      this.fetchPayroll();
    },
    advanceMonths(months) {
      this.referenceDate = new Date(
        this.referenceDate.getFullYear(),
        this.referenceDate.getMonth() + months,
        this.referenceDate.getDate()
      );

      this.fetchPayroll();
    },
    calculateDebts(debts) {
      return debts?.reduce((t, e) => t + e.amount, 0) || 0;
    },
    fetchPayroll() {
      const url = new URL(`${this.$store.state.apiUrl}payrolls`);
      url.search = new URLSearchParams({
        firm_id: this?.firm?.uid,
        start_in: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth(),
          1
        ),
        end_in: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth() + 1,
          0
        ),
      });

      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.payrolls = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    fetchFirms() {
      const url = new URL(`${this.$store.state.apiUrl}firms`);
      if (this.filterOptions && Object.keys(this.filterOptions).length)
        url.search = new URLSearchParams(this.filterOptions);
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.firms = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
const firstDayInMonth = (reference) =>
  new Date(reference.getFullYear(), reference.getMonth(), 1);
const lastDayInMonth = (reference) =>
  new Date(reference.getFullYear(), reference.getMonth() + 1, 0);
const salaryPerDay = (salary) => salary / 30;
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>