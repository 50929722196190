<template>
  <el-dialog title="Filtrar contas" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4>Período de vencimento:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.expires_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.expires_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de pagamento:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.paid_at_start"
            type="date"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.paid_at_end"
            type="date"
            size="medium"
            placeholder="Fim:"
            :default-time="new Date(0, 0, 0, 23, 59, 59)"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-select
        remote
        filterable
        clearable
        :loading="!firms"
        :remote-method="fetchFirms"
        value-key="uid"
        v-model="filterOptions.firm_id"
        size="medium"
      >
        <template #prefix>Empresa:</template>
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        remote
        filterable
        clearable
        v-model="filterOptions.partner_id"
        size="medium"
        value-key="uid"
        :remote-method="fetchPartners"
        :loading="!partners"
      >
        <el-option
          v-for="item in Partners"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
        <template #prefix>Fornecedor:</template>
      </el-select>

      <el-select
        remote
        filterable
        clearable
        v-model="filterOptions.cost_id"
        :remote-method="fetchCosts"
        size="medium"
        value-key="uid"
      >
        <template #prefix>Centro Custo:</template>
        <el-option
          v-for="item in Costs"
          :key="item.uid"
          :label="item.reference"
          :value="item.uid"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="clearFilter"
          >Limpar filtros</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="updateFilters"
          >Filtrar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
export default {
  props: ["showModal", "filters"],
  emits: ["close-modal", "update-filters"],
  data() {
    return {
      filterOptions: {},
      isLoadingSave: false,
      firms: null,
      costs: null,
      partners: null,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchFirms();
    this.fetchCosts();
    this.fetchPartners();
  },
  watch: {
    filters(v) {
      if (v) {
        this.filterOptions = v;
      }
    },
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Costs() {
      return this.costs || [];
    },
    Firms() {
      return this.firms || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    fetchFirms(v) {
      const url = new URL(`${this.$store.state.apiUrl}firms`);
      url.search = new URLSearchParams({
        name: v === true ? "" : v,
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
            this.$emit("fetch-firms", json);
          });
    },
    fetchCosts(v) {
      const url = new URL(`${this.$store.state.apiUrl}costs`);
      url.search = new URLSearchParams({ searchName: v === true ? "" : v });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.costs = json;
            this.$emit("fetch-costs", json);
          });
    },
    fetchPartners(v) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: v === true ? "" : v,
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.partners = json));
    },
    updateFilters() {
      this.$emit("update-filters", this.filterOptions);
      this.shouldShow = false;
    },
    clearFilter() {
      this.filterOptions = {};
      this.updateFilters();
    },
  },
  name: "FilterBillsModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>